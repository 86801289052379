import React from "react";
import ReactDOM from "react-dom/client";
import NavBar from "./navbar";
import { ElfsightWidget } from "react-elfsight-widget";

// import DarkMode from "./darkmode";
// import ContactForm from "./form";
import FloatingSideButton from "./netmaid";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <div>
      {/* <DarkMode /> */}
      <NavBar />
      <FloatingSideButton />
      <ElfsightWidget widgetId="3c40e294-bfe3-45ce-8e88-f37bd21d3787" />
    </div>
  </>
);
